import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import * as URL from "service/UrlConstant";
import {QueriesPanelContext} from "./context";
import {SecurityContext} from "context/security";
import {
    dateToString, getCurBusDate,
    getMinPermissibleBusDate, getPrevBusDate, pickerDateFormatToDate,
    yearMonthDayToPickerDateFormat
} from "utils/FormatUtils";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {QUERY_PANEL_VIEW_QUERY_PAGE} from "./context/QueriesPanelContext";
import {ToastContext} from "context/Toast";
import {queryGridDataColumnDefs as gridDataColumnDefs} from "./QueryColumnDefs"
import {FilterContext} from "layouts/SidePanelLayout";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {VALIDATION_IN_JS} from "../../utils/Constants";
import {isValidDate} from "../../utils/RegexUtils";
import {updateQueriesFilterFormErrors} from '../../utils/ErrorUtils';


export default function QueriesFilter() {

    const {
        queryPanelPageState,
        queryPanelFilterState,
        setQueryPanelFilterState,
        clearQueryPanelDisplayState,
        queryResultsDisplayState,
        setQueryResultsDisplayState,
        displayQueryPanelPage,
        isHomeRequest,
        setIsHomeRequest,
        setShowGridToolbar, isDefaultEndDate,
        setIsDefaultEndDate} = useContext(QueriesPanelContext);
    queryResultsDisplayState.savedQueries = undefined;
    const {setDisableAccount,entityAccounts, setShowAccounts, clearEntityAccounts, validateEntityAccountSearch, setManagerAndAccountDetails, populateDefaultEntity, entityAccountsOptions} = useContext(EntityAccountsContext);
    const [queriesList, setQueriesList] = useState([]);
    const {makeGetRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);
    const [formErrors, setFormErrors] = useState({search: {}});
    const {showFilter, setShowFilter} = useContext(FilterContext);

    const getQueriesReport = () => {

        const onSuccess = (response) => {
            //Only add supported queries to the list for display to the user
            const filteredQueryList = response.data.filter(checkForSupportedQueries);
            setQueriesList(filteredQueryList)
        }
        const onError = (err) => {
            console.error(err);
        }
        makeGetRequest(URL.QUERIES, {"accountId":entityAccounts.accountId}, onSuccess, onError);
    }

    function checkForSupportedQueries(value) {
        return gridDataColumnDefs.has(value.queryId);
    }

    const validateSearch = ()=>{
        let errors = {}
        errors = validateEntityAccountSearch();
        let {queryId, startDate, endDate} = queryPanelFilterState;
        const validDates = isValidDate(startDate) && isValidDate(endDate);

        if(!queryId){
            errors['query'] = 'Please select query'
        }

        if(!startDate || !isValidDate(startDate))
            errors['startDate'] = 'Please input start date'

        if(validDates){
            const startDateD = new Date(startDate).getTime();
            const endDateD = new Date(endDate).getTime();

            if (startDateD > endDateD){
                errors['startDate'] = 'Please ensure that the start date is before the end date'
                errors['endDate'] = 'Please ensure that the start date is before the end date'
            }
        }

        if (startDate && (dateToString(startDate) < getMinPermissibleBusDate()))
            errors['startDate'] = `Please ensure that the start date is on or after ${pickerDateFormatToDate(getMinPermissibleBusDate())}`;

        if (startDate &&  (dateToString(startDate) > getCurBusDate()))
            errors['startDate'] = `Please ensure that the start date is on or before ${pickerDateFormatToDate(getCurBusDate())}`;
        if (startDate &&  (dateToString(endDate) > getCurBusDate()))
            errors['endDate'] = `Please ensure that the end date is on or before ${pickerDateFormatToDate(getCurBusDate())}`;

        return errors
    }

    const isQueryNameDuplicate = (queryName) => {
        const trimmedQueryName = queryName.trim();
        const existingSavedQueries = queryResultsDisplayState.savedQueries;

        return existingSavedQueries.some(
            (query) => query.queryName.toLowerCase().trim() === trimmedQueryName.toLowerCase()
        );
    };



    const handleViewQuery = (e) => {
        e.preventDefault();

        let errors = {}
        if (VALIDATION_IN_JS) {
            errors = validateSearch();
        }
        const newFormErrors = {...formErrors};
        newFormErrors['search'] = errors;
        setFormErrors(newFormErrors)
        if(Object.keys(errors).length!=0){
            return
        }

        const trimmedQueryName = queryPanelFilterState.queryName.trim();

        if (trimmedQueryName === '') {
            errors['queryName'] = 'Query name is required';
            setFormErrors({...formErrors, search: errors });
            return;
        }

        let searchParams = {
            clientCode: (entityAccounts.disableAccounts )
                                ? entityAccounts.managerName.substring(0,entityAccounts.managerName.indexOf('-'))
                                : entityAccounts.accountCode,
            queryId:queryPanelFilterState.queryId,
            startDate:dateToString(queryPanelFilterState.startDate),
            endDate:dateToString(queryPanelFilterState.endDate)
        }

        const onSuccError = (res) => {
            const queryResultsDisplay = JSON.parse(JSON.stringify(queryResultsDisplayState));
            queryResultsDisplay.resData = ([...res.data])
            queryResultsDisplay.isSavedQuery = false;
            queryResultsDisplay.savedName = '';
            queryResultsDisplay.savedQueryId = '';
            queryResultsDisplay.querySearch = {
                queryId : queryPanelFilterState.queryId,
                queryName : queryPanelFilterState.queryName,
                startDate : queryPanelFilterState.startDate,
                endDate : queryPanelFilterState.endDate,
                managerId : entityAccounts.managerId,
                managerName : entityAccounts.managerName,
                subManagerId : entityAccounts.subManagerId,
                subManagerName : entityAccounts.subManagerNameShort,
                accountId : entityAccounts.accountId,
                accountName : entityAccounts.accountName,
                accountCode : entityAccounts.accountCode
            };

            setShowGridToolbar(res.data && res.data.length > 0)
            setQueryResultsDisplayState(queryResultsDisplay);
            displayQueryPanelPage(QUERY_PANEL_VIEW_QUERY_PAGE);

        }

        setShowFilter(!showFilter)

        const onSuccess = (res) => {
            onSuccError(res)
        }
        const onError = (err) => {
            let res = { data : [] }
            onSuccError(res)
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving Query - ', errorMsg);
            error("There was an error with loading selected query");
        }

        const sDate = searchParams.startDate;
        searchParams = !searchParams.endDate || searchParams.endDate.trim().length === 0 ?
            {...searchParams, endDate: sDate} : searchParams;
        makeGetRequest(URL.QUERIES_QUERY, searchParams, onSuccess, onError);
    }

    const handleClear = () => {
        clearQueryPanelDisplayState();
        clearEntityAccounts();
    }


    const handleQueryChange = (e, name) => {
        const targetValue = e.currentTarget.value;
        if (queryPanelFilterState.queryId !== targetValue) {
            const queryPanelFilterStateSaved = JSON.parse(JSON.stringify(queryPanelFilterState));
            queryPanelFilterStateSaved.queryId = targetValue;
            queryPanelFilterStateSaved.queryName = e.currentTarget.options[e.currentTarget.selectedIndex].innerHTML;
            setQueryPanelFilterState(queryPanelFilterStateSaved);
        }
        updateQueriesFilterFormErrors(name, targetValue,formErrors,
            setFormErrors, queryPanelFilterState)
    }

    const handleDateChange = (e) => {
        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        const name = e.target.name;
        const queryPanelFilterStateSaved = JSON.parse(JSON.stringify(queryPanelFilterState));
        queryPanelFilterStateSaved[targetName] = targetValue;
        setQueryPanelFilterState(queryPanelFilterStateSaved);
        updateQueriesFilterFormErrors(name, targetValue,formErrors,
            setFormErrors, queryPanelFilterState)
    }

    useEffect(() => {
        populateDefaultEntity(true)
    }, [entityAccountsOptions])

    useEffect(() => {
        setShowAccounts(true);
        getQueriesReport();
        if(isHomeRequest)
            setIsHomeRequest(false)
    },[entityAccounts.accountId])

    useEffect(() => {
        if (QUERY_PANEL_VIEW_QUERY_PAGE === queryPanelPageState.currentPage && queryResultsDisplayState.isSavedQuery) {
            const queryPanelFilterStateSaved = JSON.parse(JSON.stringify(queryPanelFilterState));
            queryPanelFilterStateSaved.queryName = queryResultsDisplayState.querySearch.queryName;
            queryPanelFilterStateSaved.queryId = queryResultsDisplayState.querySearch.queryId;
            queryPanelFilterStateSaved.startDate = yearMonthDayToPickerDateFormat(queryResultsDisplayState.querySearch.startDate);
            queryPanelFilterStateSaved.endDate = yearMonthDayToPickerDateFormat(queryResultsDisplayState.querySearch.endDate);
            setQueryPanelFilterState(queryPanelFilterStateSaved);

            setManagerAndAccountDetails(queryResultsDisplayState.querySearch.managerId, queryResultsDisplayState.querySearch.managerName,
                queryResultsDisplayState.querySearch.accountId, queryResultsDisplayState.querySearch.accountName,
                queryResultsDisplayState.querySearch.accountCode, true);
        }
    }, [queryPanelPageState]);

    useEffect(()=>{
        if(isDefaultEndDate && !queryResultsDisplayState.isSavedQuery && !isHomeRequest){
            setQueryPanelFilterState((curQueryPanelFilterState)=>({
                ...curQueryPanelFilterState,
                endDate:''
            }));
        }

    },[isDefaultEndDate])

    return(<>
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form>
                <h2 className='d-none d-lg-block'>Search</h2>
                <Row>
                    <EntityAccountsComponent/>

                    <Col md={'12'}>
                        <Form.Group controlId="formQueryName" className={'td-select'} id={(!!formErrors.search.query? 'td-select-error':'')}>
                            <Form.Label className="filter-input-label">Query *</Form.Label>
                            <Form.Control as="select" name={'queryId'} value={queryPanelFilterState.queryId} onChange={event => handleQueryChange(event, 'query')}
                                          isInvalid={!!formErrors.search.query}>
                                <option value={''}>Select</option>{
                                    queriesList.map(data=> <option key={data.queryId} value={data.queryId}>{data.queryName}</option>)
                                }
                            </Form.Control>
                            <Form.Control.Feedback type={'invalid'}>
                                <b><i className="bi bi-exclamation-circle"></i>{formErrors.search.query}</b>
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={'12'} sm={'6'}>
                        <Form.Group controlId="formQueryStartDate">
                            <Form.Label className="filter-input-label">Start Date *</Form.Label>
                            <Form.Control type="date" name={'startDate'} value={queryPanelFilterState.startDate} onChange={handleDateChange} isInvalid={!!formErrors.search.startDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.startDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={'12'} sm={'6'}>
                        <Form.Group controlId="formQueryEndDate">
                            <Form.Label className="filter-input-label">End Date</Form.Label>
                            <Form.Control type="date" name={'endDate'} value={queryPanelFilterState.endDate} onChange={handleDateChange} isInvalid={!!formErrors.search.endDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.endDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={'12'}>
                        <button className="btn btn-block td-btn-primary-light my-3" onClick={handleViewQuery}>View Query</button>
                    </Col>
                    <Col md={'12'}>
                        <button className="btn btn-block td-btn-secondary-clear my-3" onClick={handleClear}>Clear</button>
                    </Col>
                </Row>
            </Form>

        </Container>
    </>);
}
