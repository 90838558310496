import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import useWindowDimensions from "hooks/Dimensions";
import {TradeCaptureContext, TradeBreakContext} from "./context";
import {yearMonthDayToPickerDateFormat} from "utils/FormatUtils";
import {TRADES_LIST_TRADE_BREAKS} from "service/UrlConstant";
import {SecurityContext} from "context/security";
import AuthenticationService from "service/AuthenticationService";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {DEFAULT_GRID_PAGINATION_SIZE, ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE, TRADE_BREAK_STATE} from "utils/Constants";
import InfoLayoutTradeBreak from "./InfoLayoutTradeBreak";
import AgGridToolbar from "components/AgGridToolbar/AgGridToolbar";
import {createExcelExportParams, getFilteredColumns} from "../../utils/ExcelExportUtils";
import {UtilsContext} from "../../context/utils";
import {ActionPanelContext} from "../../context/action";
import * as URL from "../../service/UrlConstant";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import {getSessionAttribute, setSessionAttribute} from "../../utils/SessionUtils";


export default function TradeBreaks(){
    const {popUpData, setPopUpData, highlightTradeEntryFields, setHighlightTradeEntryFields, selectedBrkType, setSelectedBrkType, tradeBreakTypeHashmap, accountCodeMapping, columnDefsTradeBreaks, showInfoModal, setShowInfoModal,getReasonEquivalentOfField, cellStyleChecker, setSelectedBreak} = useContext(TradeBreakContext);
    const {tradeCaptureDisplayState, setTradeCaptureDisplayState} = useContext(TradeCaptureContext);
    const {setManagerDetails, setEntityAccountsOptions, setAccountsOptions} = useContext(EntityAccountsContext);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState([]);
    const [gridColumnApi,setGridColumnApi] = useState(null);

    const myGrid = useRef(null);
    const [actionForAccount, setActionForAccount] = useState('');
    const {isMobile} = useWindowDimensions();
    const {hiddenExportGridApi} = useContext(UtilsContext)
    const {setHandleRefreshFunction, setHandleExportFunction} = useContext(ActionPanelContext);

    const {paginationPageSize, showButton, setShowButton,setPaginationPageSize} = useContext(UtilsContext);



    useEffect(() => {
        setHandleRefreshFunction({handleRefresh: loadTradeBreaksList, gridApi:null });
        setHandleExportFunction({handleExport:exportTradebreakList, gridApi: gridApi});
    }, [rowData])

    useEffect(() => {
        setupActionColumns();
    }, [])

    const exportTradebreakList = (type, rowData, gridApi, onSuccess, onError) => {
        //Set Column definition
        let columnDefsToExport = columnDefs.filter(e=>!e.suppressColumnsToolPanel)
        hiddenExportGridApi.current.api.setColumnDefs(columnDefsToExport);
        //Set Data
        hiddenExportGridApi.current.api.setRowData(rowData);
        let filteredColumnNames = getFilteredColumns(gridRef);
        //Export as csv/excel
        type==='EXCEL' ?
            hiddenExportGridApi.current.api.exportDataAsExcel(createExcelExportParams('TradeBreaks', filteredColumnNames)) :
            hiddenExportGridApi.current.api.exportDataAsCsv({ columnKeys: filteredColumnNames})
    }



    const {
        makeGetRequest
    } = useContext(SecurityContext);

    useEffect(()=>{
        loadTradeBreaksList()
    },[])

    const loadTradeBreaksList = () => {
        const onSuccess = (response) => {
            if(!!response.data && response.data.length > 0){
                setRowData([...response.data]);
            }
            gridColumnApi.resetColumnState();
        }
        const onError = (err) => {
            console.error("Trade Breaks data",err);
        }

        makeGetRequest(TRADES_LIST_TRADE_BREAKS, {
        }, onSuccess, onError);
    };


    const setupActionColumns = () => {
        let updatedColumnDefs = [...columnDefsTradeBreaks];
        updatedColumnDefs.splice(0, 0 ,{headerName: '', cellRenderer: 'viewCellRenderer', suppressColumnsToolPanel:true, pinned:'left', width:45,cellRendererParams: {
                clicked: (props) => onInfoClicked(props)
            }});
        setColumnDefs(updatedColumnDefs);
    }

    const defaultColDef = {
        sortable:true,
        resizable:true,
        floatingFilter:true,
        enableCellChangeFlash:true,
        lockPinned:isMobile?true:false,
        width:'auto',
        cellStyle: cellStyleChecker,
        filter: 'agTextColumnFilter'
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
        params.columnApi.setColumnState(getSessionAttribute(TRADE_BREAK_STATE));
    }


    const onInfoClicked = (field) => {
        const selectedBreak = {};
       selectedBreak['id'] = field.data.id;
         selectedBreak['managerId'] = field.data.managerId;
         selectedBreak['omniQuantity'] = parseInt(field.data.clientQuantity.replace(/,/g,''));
         selectedBreak['omniCommission'] = field.data.clientCommission;
        setSelectedBreak(selectedBreak);
        const popUpDataState = JSON.parse(JSON.stringify(popUpData));
        const highlight= JSON.parse(JSON.stringify(highlightTradeEntryFields));
        setShowInfoModal(true);
        setSelectedBrkType('Break Type : '+tradeBreakTypeHashmap[field.data.breakType]);
        Object.entries(field.data).map(([key, value]) => {
            popUpDataState.map(e=>{
                if(key.replace('client','').toLowerCase()===e.field.toLowerCase()){
                    e.client = value;
                    e.isBreak = field.data.clientReason.includes(getReasonEquivalentOfField(e.field))?true:false
                    highlight[e.field] = e.isBreak;
                }
                else if(key.replace('broker','').toLowerCase()===e.field.toLowerCase()){
                    e.broker = value;
                    e.isBreak = field.data.brokerReason.includes(getReasonEquivalentOfField(e.field))?true:false
                }
            })
        })
        setHighlightTradeEntryFields({...highlight})
        setPopUpData(popUpDataState)
        setActionForAccount(accountCodeMapping(field));
    }


    const viewCellRenderer = function (props){
        return (<>
            {(tradeBreakTypeHashmap[props.data['breakType']] === 'Match with Differences' || tradeBreakTypeHashmap[props.data['breakType']] === 'Client Only')  &&
                <a className="td-link" href="#" onClick={() => {
                    //onViewClicked(props.data);
                    onInfoClicked(props)
                }}>View</a>
            }
        </>);
    }

    const rowClassRules = useMemo(() => {
        return {
            'grey-out': (params) => {
                return params.data.isCorrected>0;
            },

        };
    }, []);

    const sizeToFit = useCallback(() => {
        gridRef.current.api.setColumnDefs(gridRef.current.api.getColumnDefs().filter(e=>e.field!=='flex'))
        gridRef.current.api.sizeColumnsToFit({
            defaultMinWidth: 100,
            columnLimits: [{ key: 'flex',flex:-1 }],
        });
    }, []);
    const onFirstDataRendered = (params) => {
        const pageToNavigate = JSON.parse(getSessionAttribute(TRADE_BREAK_STATE));
        params.api.paginationGoToPage(pageToNavigate);
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onPaginationChange = (params) => {
        let currentPage = params.api.paginationGetCurrentPage();
        if (params.newPage && params.api.paginationGetTotalPages() !== 0) {
            setSessionAttribute(TRADE_BREAK_STATE, JSON.stringify(currentPage));
        }
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    const onStateUpdated = (params) => {
        console.log("State Updated: ", gridApi.getState())
        // gridapi.state
        setSessionAttribute(TRADE_BREAK_STATE, gridApi.getState());
    }
    return(<>

        <Container fluid style={{ height: '100%' }} className={"break-grid "} >

            <Row className="pt-3 pb-2 pt-md-4 pb-md-3">
                <Col>
                    <h1>Trade Breaks</h1>
                </Col>
            </Row>
            <Row className="pb-1 pb-md-2">
                <Col>
                    <h3>&nbsp;</h3>
                </Col>
            </Row>
            {<AgGridToolbar

                tradeBreak={true}
                gridApi={gridApi}
                gridColumnApi={gridColumnApi}
                sizeToFit = {sizeToFit}
                columnDefs = {columnDefs}
                setColumnDefs = {setColumnDefs}
                rowData={rowData}
                paginationPageSize={paginationPageSize}
                setPaginationPageSize={setPaginationPageSize}
                >
            </AgGridToolbar>}
            <div
                style={{ height: '100%' }}
                ref={myGrid}
                id="myGrid"
                className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    pagination={true}
                    paginationPageSize={DEFAULT_GRID_PAGINATION_SIZE}
                    frameworkComponents={{
                        viewCellRenderer:viewCellRenderer,
                    }}
                    domLayout={"autoHeight"}
                    groupDefaultExpanded={'1'}
                    enableRowGroup={false}
                    suppressDragLeaveHidesColumns={true}
                    suppressMakeColumnVisibleAfterUnGroup={true}
                    suppressModelUpdateAfterUpdateTransaction={true}
                    suppressScrollOnNewData={true}
                    suppressAggFuncInHeader={true}
                    allowShowChangeAfterFilter={false}
                    rowGroupPanelShow={'never'}
                    enableSorting={true}
                    enableFilter={true}
                    onGridReady={onGridReady}
                    defaultColDef={defaultColDef}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    rowClassRules={rowClassRules}
                    onFirstDataRendered={onFirstDataRendered}
                    onRowDataChanged={onFirstDataRendered}
                    onPaginationChanged={onPaginationChange}
                    stateUpdated={onStateUpdated}
                >
                </AgGridReact>
            </div>
        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}
        <InfoLayoutTradeBreak show={showInfoModal} setShow={setShowInfoModal} popUpData={popUpData} actionForAccount={actionForAccount} secondTitle={selectedBrkType}/>
    </>);
}


