import {Col, Container, Row, Table} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import useWindowDimensions from "../../hooks/Dimensions";
import * as Routes from "../../routes/Routes";
import {useHistory} from "react-router-dom";
import {TradeBreakContext, TradeCaptureContext} from "./context";
import {TRADES_BREAK_DETAIL_URL} from "../../service/UrlConstant";
import {SecurityContext} from "../../context/security";
import {yearMonthDayToPickerDateFormat} from "../../utils/FormatUtils";
import {EntityAccountsContext} from "../../components/entity-accounts/context";
import {ToastContext} from "../../context/Toast";

export default function InfoModalBody() {

    const {popUpData,setShowInfoModal, selectedBreak, setSelectedBreak, viewClicked, setViewClicked} = useContext(TradeBreakContext);
    const {makeGetRequest} = useContext(SecurityContext);
    const {setManagerAndAccountDetails, clearEntityAccounts} = useContext(EntityAccountsContext);
    const {tradeCaptureDisplayState, setTradeCaptureDisplayState, clearTradeEntry} = useContext(TradeCaptureContext);
    const {error} = useContext(ToastContext);
    const {isMobile, isMobileRotated} = useWindowDimensions();
    const [disableViewButton, setDisableViewButton] = useState(false);
    let history = useHistory();

    function navigateToPage(path) {
        history.push(path);
    }
    useEffect(()=>{
        setDisableViewButton(selectedBreak.errorMsg)
    },[selectedBreak.errorMsg])

    const onViewClicked = ()=> {
        clearEntityAccounts(false, false);
        clearTradeEntry();
        let errorMsg = "";
        const onSuccess = (response) => {
            if (response.data.size === 0)
                return
            if (response.data[0].tradeBreakComments !== null && (response.data[0].tradeBreakComments.indexOf("No allocations found for break") !== -1 )) {
                errorMsg = response.data[0].tradeBreakComments;
                setSelectedBreak({...selectedBreak,"errorMsg":errorMsg})
                return
            }
            const omniTradeState = response.data.map(item => {
                if(item.accountCode.match(/^91/)){
                    return item;
                }
                }
            );
            let finalOmniTrade;
            for(let x=0; x<omniTradeState.length; x++){
                if(omniTradeState[x]) finalOmniTrade = omniTradeState[x];
                if(omniTradeState[x]) finalOmniTrade = omniTradeState[x];
            }
            if(finalOmniTrade === null || finalOmniTrade === undefined || finalOmniTrade.length <1){
                finalOmniTrade.errorMsg = "No OMNI found"
                console.error("No OMNI found");
                setSelectedBreak({...selectedBreak,'errorMsg':"No OMNI found"})
                navigateToPage(Routes.TRADECAPTURE_BREAKS)
            }
            // console.log("OMNI: ", omniTradeState)
            // console.log("FINAL OMNI", finalOmniTrade)
            const res = finalOmniTrade;
            console.log("RES:", res)
            setManagerAndAccountDetails(selectedBreak.managerId, null, res.accountId, null, res.accountCode, true);
            const newTradeState = JSON.parse(JSON.stringify(tradeCaptureDisplayState));

            //Trade entry data
            newTradeState.tradeEntry.isTradeBreak = true
            newTradeState.tradeEntry.isTradeBreakReadonly = false
            newTradeState.tradeEntry.isOverride = true
            newTradeState.tradeEntry.managerId = selectedBreak.managerId
            newTradeState.tradeEntry.accountId = res.accountId
            newTradeState.tradeEntry.accountType = res.accountType?.trim()
            newTradeState.tradeEntry.tradeType = res.tradeType.trim()
            newTradeState.tradeEntry.quantity = res.quantity
            newTradeState.tradeEntry.securityOption = res.securityOption
            newTradeState.tradeEntry.security = res.securityCode
            newTradeState.tradeEntry.securityOptionSelected = res.securityCode
            newTradeState.tradeEntry.price = res.price
            newTradeState.tradeEntry.status = res.status
            newTradeState.tradeEntry.currency = res.currency ? res.currency.trim() : ''
            newTradeState.tradeEntry.brokerCode = res.brokerCode ? res.brokerCode.trim() : ''
            newTradeState.tradeEntry.commissionType = res.commissionType
            newTradeState.tradeEntry.commission = res.commission
            newTradeState.tradeEntry.tradeDate = res.tradeDate ? yearMonthDayToPickerDateFormat(res.tradeDate.toString()) : ''
            newTradeState.tradeEntry.settleDate = res.settleDate ? yearMonthDayToPickerDateFormat(res.settleDate.toString()) : ''
            newTradeState.tradeEntry.accruedInterest = res.accruedInterest
            newTradeState.tradeEntry.comments = res.comments
            newTradeState.tradeEntry.clientId = res.clientId
            newTradeState.tradeEntry.openClose = res.openClose
            newTradeState.tradeEntry.indTradeCancel = res.tradeCancelIndicator
            newTradeState.tradeEntry.fxUSDRate = res.fxUSDRate
            newTradeState.tradeEntry.tradeBridgeTradeId = res.tradeBridgeTradeId
            newTradeState.tradeEntry.version = res.version
            newTradeState.tradeEntry.dbSource = res.dbSource
            newTradeState.tradeEntry.cdBlockIdClient = res.cdBlockIdClient
            newTradeState.tradeEntry.tradeBreakComments = res.tradeBreakComments

            //Trade search data
            newTradeState.tradeActivitiesSearch.managerId = selectedBreak.managerId;
            newTradeState.tradeActivitiesSearch.managerName = null;
            newTradeState.tradeActivitiesSearch.accountId = null;
            newTradeState.tradeActivitiesSearch.accountCode = null//?
            newTradeState.tradeActivitiesSearch.accountName = null;
            newTradeState.tradeActivitiesSearch.status = 'ALL'
            newTradeState.tradeActivitiesSearch.startDate = res.tradeDate
            newTradeState.tradeActivitiesSearch.endDate = res.tradeDate

            const resData = [];
            response.data.map(e => {
                const allocation = {}
                allocation.accountId = e.accountId;
                allocation.managerId = selectedBreak.managerId;
                allocation.accountType = e.accountType;
                allocation.tradeType = e.tradeType??null;
                allocation.quantity = e.quantity;
                allocation.securityCode = e.securityCode;
                allocation.price = e.price;
                allocation.currency = e.currency;
                allocation.brokerCode = e.brokerCode;
                allocation.commissionType = e.commissionType;
                allocation.commission = e.commission;
                allocation.tradeDate = e.tradeDate ? yearMonthDayToPickerDateFormat(e.tradeDate.toString()) : '';
                allocation.settleDate = e.settleDate ? yearMonthDayToPickerDateFormat(e.settleDate.toString()) : ''
                allocation.accruedInterest = e.accruedInterest
                allocation.comments = e.comments
                allocation.clientId = e.clientId
                allocation.isOverride = null;
                allocation.dbSource = "TRADE_BRIDGE";
                allocation.version = e.version;
                allocation.tradeBridgeTradeId = e.tradeBridgeTradeId//?,
                allocation.cdBlockIdClient = e.cdBlockIdClient
                allocation.accountName = null;
                allocation.managerName = null;
                allocation.securityOption = null;
                allocation.fxUSDRate = null;
                allocation.tradeUploadId = null;
                allocation.effectiveDate = null;
                allocation.p3Status = null;
                allocation.openCloseCode = null;
                allocation.cancelIndicator = null;
                allocation.originalDate = null;
                allocation.p3TradeNumber = null;
                allocation.p3Comment = null;
                allocation.tradeTypeValue = null;
                allocation.accountTypeId = null;
                allocation.brokerDescription = null;
                allocation.CancelledIndicator = null;
                allocation.cancelledIndicator = null;
                resData.push(allocation);
            })
            newTradeState.tradeActivitiesSearch.resData = resData;
            setTradeCaptureDisplayState(newTradeState)
            setViewClicked(true);
            // setShowInfoModal(false);
        }
        const onError = (err) => {
            console.error("Trade Breaks data", err);
            setSelectedBreak({...selectedBreak,'errorMsg':"Error in mapping allocations"})
            navigateToPage(Routes.TRADECAPTURE_BREAKS)
        }

        makeGetRequest(TRADES_BREAK_DETAIL_URL, {"tradeBreakId": selectedBreak.id}, onSuccess, onError);
        if (errorMsg === "")
            navigateToPage(Routes.TRADECAPTURE_ENTRY + "/TradeBreak")
        else
        {
            setSelectedBreak({...selectedBreak,errorMsg:errorMsg});
            setDisableViewButton(true)
        }
    }

    return (
        <Container fluid className={"my-3 my-md-0 popUp2"}>
            <div>
                <p>Break ID: {selectedBreak.id}</p>
            </div>


            <Table striped bordered hover className={'custom-table'}>
                <thead>
                <tr>
                    <th ></th>
                    <th style={{width:'33%'}}>Client Trade</th>
                    <th style={{width:'33%'}}>Broker Trade</th>
                </tr>
                </thead>
                <tbody>
                {
                    popUpData
                        .map((e) =>
                            (
                                <tr style={{height:'10px'}}>
                                    <td>{e.header}</td>
                                    <td className={e.isBreak?'clr':''}>{e.client}</td>
                                    <td className={e.isBreak?'clr':''}>{e.broker}</td>
                                </tr>
                            )
                        )
                }
                </tbody>
            </Table>

            {selectedBreak.errorMsg &&
            <Row className={"viewBtn justify-content-end"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <b className={"error"}><p><i className="bi bi-exclamation-circle"></i>{selectedBreak.errorMsg}</p></b>
                </Col>
            </Row>
            }
            <Row className={"justify-content-end viewBtn"}>
                <Col md={4} xs={12} className='pr-0 d-none d-md-block'>
                    <button disabled={false} className='btn btn-block td-btn-secondary-clear' href="#" onClick={() => {
                        setShowInfoModal(false);
                    }}>Cancel
                    </button>
                </Col>
                <Col md={4} xs={11} sm={12}
                     className={isMobileRotated ? 'stick-to-botom' : isMobile ? 'stick-to-bottom' : ''}>
                    <button disabled={disableViewButton} className={"btn btn-block td-btn-primary-light"} href="#" onClick={() => {
                        onViewClicked();
                    }}>View
                    </button>
                </Col>
            </Row>

        </Container>
    );

}

