import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Row, Col, Container, Card, Collapse} from "react-bootstrap";
import {AgGridReact} from "ag-grid-react";
import CheckboxRenderer from "components/CheckboxRenderer";
import useWindowDimensions from "hooks/Dimensions";
import {SecurityContext} from "context/security";
import { useHistory} from "react-router-dom";
import {EntityAccountsContext} from "components/entity-accounts/context";
import { getCurrentDate, getCurrentDateToDatePickerFormat} from "utils/FormatUtils";
import {SECURITY_SEARCH_URL, SHORT_SALE_URL_LOCATE, } from "service/UrlConstant";
import {ToastContext} from "context/Toast";
import {ShortSaleContext} from "./context";
import { SHORTSALE_APPROVAL_LOCATE} from "../../routes/Routes";
import ResponseToast from "../../components/ResponseToast";
import {
    GRID_COLUMN_PROPERTY,
    DEFAULT_GRID_PAGINATION_SIZE,
    VALIDATION_IN_JS,
    REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY, ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE
} from "utils/Constants";
import AuthenticationService from "../../service/AuthenticationService";
import {
    REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_QUANTITY_MSG,
    REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY_MSG
} from "../../utils/ConstantsMsg";
import {addPropertiesToGridColumns, getDisplayedNumberOfGridRows} from "../../utils/gridColumnUtils";
import AgGridToolbar from "../../components/AgGridToolbar/AgGridToolbar";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {validateValueOnRegex} from "../../utils/RegexUtils";
import {UtilsContext} from "../../context/utils";
import ScrollButton from "../../components/AgGridToolbar/ToolBarButtons/ScrollButton";
import {numberFormat} from "../../utils/NumberUtils";

export default function ShortSalePasteFromClipboard() {
    const {hiddenExportGridApi,paginationPageSize, setPaginationPageSize, showButton, setShowButton} = useContext(UtilsContext)
    let history = useHistory();
    const {setShortSaleApprovalSearchDisplayState,handlePageRequest,panelDisplayState, setPanelDisplayState, setShortSaleApprovalPasteFromClipboardState,shortSaleApprovalPasteFromClipboardState, setSSAToolBarRefreshCriteria, setShowBadge} = useContext(ShortSaleContext);
    const {success,alert,error} = useContext(ToastContext);
    const {setManagerDetails, setEntityAccountsOptions, entityAccountsOptions, entityAccounts, setShowAccounts} = useContext(EntityAccountsContext);
    const [rowData, setRowData] = useState([]);
    const [showEntityAccountsDropdown, setShowEntityAccountsDropdown] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);
    const {isMobile} = useWindowDimensions();
    const {makeGetRequest} = useContext(SecurityContext);
    const {makePostRequest} = useContext(SecurityContext)

    const myGrid = useRef(null);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        floatingFilter: true,
        filter: true,
        enableCellChangeFlash: true,
        lockPinned: !!isMobile,
    };

    const columnTypes = {
        flexColType: {
            sortable: false,
            resizable: false,
            floatingFilter: false,
            filter: false,
            enableCellChangeFlash: false,
            suppressMenu: true,
            flex: 1,
            suppressMovable: true,
        }
    }

    useEffect(()=>{
        setShowAccounts(false);
        const userEntityAccounts = AuthenticationService.getEntityAccounts();
        setEntityAccountsOptions(JSON.parse(JSON.stringify(userEntityAccounts)));
        setShowEntityAccountsDropdown(userEntityAccounts.length > 1)
    },[]);

    useEffect(() => {
        if(entityAccountsOptions.length === 1 && !showEntityAccountsDropdown) {
            setManagerDetails(entityAccountsOptions[0].managerId, entityAccountsOptions[0].shortName+"-"+entityAccountsOptions[0].longName)
        }
    }, [entityAccountsOptions])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (Object.keys(shortSaleApprovalPasteFromClipboardState).length>0) {
            setRowData([...shortSaleApprovalPasteFromClipboardState.secQtyArr])
        }
    }, [shortSaleApprovalPasteFromClipboardState])

    const cellStyleChecker = (data, field) => {
        if(field === 'security' && (data.security===null || data.security.trim() ==="")) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
        if(field === 'quantity' && (isNaN(data.quantity) || data.quantity ==="")) return {backgroundColor: '#F7E8E9', color:'#AE1100'}
        else return{backgroundColor: 'white', color:'black'}
    }
    const columnDefs = [
        { checkboxSelection:true,headerCheckboxSelection: true,maxWidth :100, },
        {headerName: 'Security', field: 'security',editable: true, cellStyle:(params) => cellStyleChecker(params.data, 'security')},
        {headerName: 'Security Description', field: 'secDescription', },
        {headerName: 'Quantity', field: 'quantity',editable: true, tooltipField:'errmsgQuantity', cellStyle:(params) => cellStyleChecker(params.data, 'quantity'),valueFormatter: numberFormat},
        // {headerName: 'Entity' ,  valueGetter : () => entityAccounts.managerName, cellStyle:(params) => cellStyleChecker(params.data.secDescription) },
        {headerName: 'errmsgSecurity', field: 'errmsgSecurity', hide:true},
        {headerName: 'errmsgQuantity', field: 'errmsgQuantity',hide:true},
        {headerName: 'fileName', field: 'fileName',hide:true },
        {headerName: '', field: '', type: 'flexColType', suppressColumnsToolPanel: true},
    ];

    const updatedColumnDefs = useMemo(() => addPropertiesToGridColumns(columnDefs, GRID_COLUMN_PROPERTY), []);

    const onGridReady = (params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }

    const onFirstDataRendered = (params) => {
        params.api.forEachNode((node) => {
            if (!(node.data.errmsgSecurity || node.data.errmsgQuantity)){
                node.setSelected(true);
            }else{
                node.selectable = false
            }
        });
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }


    const onCellEditingStopped = useCallback((e) => {
        if(e.value === null || e.value==="") {
            e.api.getRowNode(e.node.rowIndex).selectable = false;
            e.api.getRowNode(e.node.rowIndex).setSelected(false);
            e.api.getRowNode(e.node.rowIndex).setDataValue(e.colDef.field,"")
            return
        }
        else if(e.data.security !== null && e.data.security.trim()!=="" && !isNaN(e.data.quantity) ){
            e.api.getRowNode(e.node.rowIndex).selectable = true;
        }

        if(e.colDef.field === 'security')
        {
            const onErrorResponse = (err) => {
                let errorMsg = getAllErrorValuesAsString(err.response.data)
                console.error("Error occurred while getting security description",errorMsg)
                error("Error occurred while getting security description. " + errorMsg);
            }
            const onSuccessResponse = (response) => {
                const securities = response.data
                let secMap = new Map()
                securities.forEach(sec => {
                    secMap.set(sec["secIdentifier"], sec["longName"])
                    e.api.getRowNode(e.node.rowIndex).setDataValue("secDescription",sec["longName"])
                })
            }
            makeGetRequest(SECURITY_SEARCH_URL, {securityCode:e.value}
                , onSuccessResponse, onErrorResponse);
        }

    }, []);

    const validateLocate = ()=>{
        let errors = {}
        if (gridApi.getSelectedRows().length>0){
            gridApi.getSelectedRows().map(e=>{
                let oneSecurity = e.security
                let matchEach = validateValueOnRegex(oneSecurity, REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY)
                if (!matchEach){
                    errors['security:'+e.security] = REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY_MSG
                }

                if(isNaN(e.quantity)){
                    errors['security'+e.security+'-quantity'+e.quantity] = REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_QUANTITY_MSG
                }
            })


        }
        return errors
    }
    const handleLocate = () => {
        const selectedData = gridApi.getSelectedRows();

        if(selectedData.length < 1){
            alert("No valid records are selected. Please paste from file excel again")
            return
        }
        let errors = {}
        if (VALIDATION_IN_JS){
            errors = validateLocate()
        }
        console.error('errors',errors)
        if(Object.keys(errors).length!=0){
        error("Please correct securities and quantities of the selected items to proceed")
            return
        }

        const data = [];
        selectedData.map(e => {
            let locateObj = {
                "processingDate": getCurrentDate(),
                "managerId": entityAccounts.managerId,
                "srcSecCd": e.security,
                "requestedQty": e.quantity,
            };
            if(entityAccounts.accountCode)
                locateObj['counterPartyAcct']=entityAccounts.accountCode;
            data.push(locateObj);
        })

        const onSuccessResponse = (response) => {
            success("Process File Locate Processed successfully");

            //Set Refresh Request Criteria
            setSSAToolBarRefreshCriteria(response, entityAccounts)

            const shortSaleStateNew = {...panelDisplayState}
            shortSaleStateNew.search.startDate = getCurrentDateToDatePickerFormat()
            shortSaleStateNew.search.endDate = getCurrentDateToDatePickerFormat()
            setPanelDisplayState({...shortSaleStateNew});
            handlePageRequest('search')
            setShowBadge(true)
            history.push(SHORTSALE_APPROVAL_LOCATE);
        }

        const onErrorResponse = (err) => {
            error("Error in processing")
        }

        makePostRequest(data,
            SHORT_SALE_URL_LOCATE, onSuccessResponse, onErrorResponse);

    }

    const handleLocateCancel = ()=>{
        history.push(SHORTSALE_APPROVAL_LOCATE);
    }
    const onPaginationChange = (params) => {
        setShowButton(getDisplayedNumberOfGridRows(gridApi) >= ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE);
    }
    return (<>
        <Container fluid>
            <Row className="py-4">
                <Col xs={12} className="pl-4">
                    <h1>Short Sale Approval Activities</h1>
                </Col>
            </Row>


            <Collapse in={true} timeout={0}>
                <Row>
                    <Col xs={12}>
                        <AgGridToolbar shortSaleApprovalScreen={true} pasteFromExcelPanel={true} gridApi={gridApi} gridColumnApi={gridColumnApi} onPageRequest={handlePageRequest}  rowData={rowData}
                                       paginationPageSize={paginationPageSize}  setPaginationPageSize={setPaginationPageSize}
                                       showButton={showButton} setShowButton={setShowButton}
                        ></AgGridToolbar>
                        <div
                            ref={myGrid}
                            id="myGrid"
                            className="ag-theme-alpine">
                            <AgGridReact
                                frameworkComponents={{
                                    checkboxRenderer: CheckboxRenderer
                                }}
                                paginationPageSize={paginationPageSize}
                                domLayout={'autoHeight'}
                                groupDefaultExpanded={'1'}
                                enableRowGroup={false}
                                suppressDragLeaveHidesColumns={true}
                                suppressMakeColumnVisibleAfterUnGroup={true}
                                suppressModelUpdateAfterUpdateTransaction={true}
                                suppressScrollOnNewData={true}
                                suppressAggFuncInHeader={true}
                                allowShowChangeAfterFilter={false}
                                rowGroupPanelShow={'never'}
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                onGridReady={onGridReady}
                                defaultColDef={defaultColDef}
                                columnDefs={updatedColumnDefs}
                                columnTypes={columnTypes}
                                rowData={rowData}
                                animateRows={true}
                                rowSelection={'multiple'}
                                onCellEditingStopped ={onCellEditingStopped }
                                onFirstDataRendered={onFirstDataRendered}
                                onRowDataChanged={onFirstDataRendered}
                                showDisabledCheckboxes={true}
                                onPaginationChanged={onPaginationChange}
                            >
                            </AgGridReact>
                        </div>
                    </Col>
                </Row>
            </Collapse>

            <Collapse in={true} timeout={0}>
                <Row className="pt-3" style={{justifyContent: 'end'}}>


                    <Col sm={6} md={4} lg={2} className="pt-4 pt-sm-2 mb-xs-2">
                        <button className="btn btn-block td-btn-primary-light" href="#"
                                onClick={handleLocate}>Locate
                        </button>
                    </Col>
                    <Col sm={12} md={4} lg={2} className="pt-4 pt-sm-2 mb-xs-2">
                        <button style={{background:'#AE1100'}} className="btn btn-block" href="#" onClick={handleLocateCancel}>Cancel
                        </button>
                    </Col>
                </Row>
            </Collapse>

        </Container>
        {showButton && <ScrollButton showButton={showButton}/>}

        <ResponseToast isFullWidth={true}/>
    </>);

}