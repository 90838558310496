import React, {useEffect} from 'react';
import * as Routes from "../../routes/Routes"
import {Accordion, Card, Nav, Row} from "react-bootstrap";
import $ from 'jquery'
import UserRoleService, {
    HOME_MENU_PERMISSION_KEY,
    QUERIES_MENU_PERMISSION_KEY,
    REPORTS_MENU_PERMISSION_KEY,
    SSA_MENU_PERMISSION_KEY,
    TRADE_MENU_PERMISSION_KEY,
    TRADE_MENU_UPLOAD_PERMISSION_KEY,
    WIRES_MENU_SUBMITTER_PERMISSION_KEY,
    WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY,
    WIRES_MENU_VIEW_ONLY_PERMISSION_KEY, TRADE_MENU_BREAK_PERMISSION_KEY
} from "service/UserRoleService";
import {TRADECAPTURE_BREAKS} from "../../routes/Routes";

export default function TDAccordionNavBar(props) {
    useEffect(() => {
        /*Logic to rotate the arrow on expanding menu*/
        $('.nav-mobile-dropdown').on('click',function(){
            $(this).find('i').toggleClass('accordion-arrow-rotate')
        })
    },[]);
    const navigateToPage = props.navigateToPage;
    const hrForTab = <Row className={'d-none d-block d-md-none py-0 pl-2 ml-1'}><hr/></Row>;

    const checkPermission = (menuKey) => {
        return UserRoleService.hasPermission(menuKey);
    }

    return (
        <>
            {checkPermission(HOME_MENU_PERMISSION_KEY) && (<Nav.Link className="tds-nav-item py-2" onClick={()=>{ navigateToPage(Routes.HOME);}}><b>Home</b></Nav.Link>)}
            {hrForTab}
            {(checkPermission(REPORTS_MENU_PERMISSION_KEY) || checkPermission(QUERIES_MENU_PERMISSION_KEY)) &&
            (<Accordion className={'py-0'}>
                <Card style={{border:'none'}}>

                    <Accordion.Toggle type='checkbox' id={'btnControl'} as={Card.Header} eventKey="0" className={'nav-mobile-dropdown'}>
                        Reporting <i className={'td-icon icon-small td-icon-downCaret accordion-arrow'} style={{position:'absolute',right:0}}/>
                    </Accordion.Toggle>

                    {checkPermission(REPORTS_MENU_PERMISSION_KEY) && (<Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.REPORTS)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Reports</Card.Body>
                    </Accordion.Collapse>)}

                    {checkPermission(QUERIES_MENU_PERMISSION_KEY) && (<Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.QUERIES)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Queries</Card.Body>
                    </Accordion.Collapse>)}
                </Card>
            </Accordion>)}


            {hrForTab}
            {(checkPermission(TRADE_MENU_PERMISSION_KEY) || checkPermission(TRADE_MENU_UPLOAD_PERMISSION_KEY)) &&
            (<Accordion className={'py-0'}>
                <Card style={{border:'none'}}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className={'nav-mobile-dropdown'}>
                        Trading <i className={'td-icon icon-small td-icon-downCaret accordion-arrow'} style={{position:'absolute',right:0}}/>
                    </Accordion.Toggle>
                    {checkPermission(TRADE_MENU_PERMISSION_KEY) && (
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.TRADECAPTURE_LIST)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Trade List</Card.Body>
                    </Accordion.Collapse>)}
                    {checkPermission(TRADE_MENU_PERMISSION_KEY) && (
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.TRADECAPTURE_ENTRY)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Trade Entry</Card.Body>
                    </Accordion.Collapse>)}
                    {checkPermission(TRADE_MENU_UPLOAD_PERMISSION_KEY) && (
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.TRADECAPTURE_UPLOAD)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Trade Upload</Card.Body>
                    </Accordion.Collapse>)}
                    {checkPermission(TRADE_MENU_BREAK_PERMISSION_KEY) && (
                        <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.TRADECAPTURE_BREAKS)}} >
                            <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Trade Breaks</Card.Body>
                        </Accordion.Collapse>)}

                </Card>
            </Accordion>)}


            {hrForTab}
            {(checkPermission(WIRES_MENU_SUBMITTER_PERMISSION_KEY) || checkPermission(WIRES_MENU_CLIENT_APPROVER_PERMISSION_KEY)  || checkPermission(WIRES_MENU_VIEW_ONLY_PERMISSION_KEY)) && (
            <Accordion className={'py-0'}>
                <Card style={{border:'none'}}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className={'nav-mobile-dropdown'}>
                        Asset Transfers <i className={'td-icon icon-small td-icon-downCaret'} style={{position:'absolute',right:0}}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.WIRES)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Wires</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>)}



            {hrForTab}
            {checkPermission(SSA_MENU_PERMISSION_KEY) && (
            <Accordion className={'py-0'}>
                <Card style={{border:'none'}}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className={'nav-mobile-dropdown'}>
                        Short Sale Approvals <i className={'td-icon icon-small td-icon-downCaret accordion-arrow'} style={{position:'absolute',right:0}}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_LOCATE)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Locate</Card.Body>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_HISTORY)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Persistent Locate</Card.Body>
                    </Accordion.Collapse>
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.SHORTSALE_APPROVAL_SEARCH)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>Search History</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>)}



            {hrForTab}
            <Accordion className={'py-0'}>
                <Card style={{border:'none'}}>
                    <Accordion.Toggle as={Card.Header} eventKey="0" className={'nav-mobile-dropdown'}>
                        Disclaimers <i className={'td-icon icon-small td-icon-downCaret'} style={{position:'absolute',right:0}}/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0"  onClick={()=>{navigateToPage(Routes.MMF)}} >
                        <Card.Body className={'nav-mobile-dropdown nav-mobile-submenu'}>MMF</Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
            {hrForTab}


        </>)
}