import React, {useContext} from 'react';
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import Routes from "routes/Routes";
import {Container, Toast} from "react-bootstrap";
import SessionExpiredModal from "components/AgGridToolbar/SessionExpiredModal";
import {SecurityContext} from "context/security";
import Login from "pages/login/Login";
import AuthenticationService from "service/AuthenticationService";
import {ToastContextProvider} from "context/Toast";
import {GridSpinner} from 'components/Spinner/GridSpinner';
import {EntityAccountsContextProvider} from "components/entity-accounts/context";
import {UtilsContextProvider} from "context/utils";
import ExportGridToCsv from "components/ExportGridToCsv";
import {TradeBreakContextProvider, TradeCaptureContextProvider} from "pages/tradecapture/context";
import {ShortSaleContextProvider} from "pages/shortsale/context";
import {ActionPanelContextProvider} from "context/action";
import {HomepageContextProvider} from "../pages/home/context";
import {UserPreferencesContextProvider} from "../context/userpreferences";
import {QueriesPanelContextProvider} from "../pages/query/context";
import {ReportsPanelContextProvider} from "../pages/reports/context";

function AppLayout() {
    const {sessionExpired, needLogin, setNeedLogin} = useContext(SecurityContext);

    if (AuthenticationService.isUserAuthenticated()) {
        if (needLogin) {
            setNeedLogin(false);
        }
    } else {
        setNeedLogin(true);
    }

    if (needLogin) return <Login/>
    else
        return (<>
                <UserPreferencesContextProvider>
                    <UtilsContextProvider>
                        <ToastContextProvider>
                            <QueriesPanelContextProvider>
                                <ReportsPanelContextProvider>
                                    <EntityAccountsContextProvider>
                                        <TradeCaptureContextProvider>
                                            <TradeBreakContextProvider>
                                                <HomepageContextProvider>
                                                    <AppHeader/>
                                                    <Container className="min-vh-100" fluid>
                                                            <ShortSaleContextProvider>
                                                                <ActionPanelContextProvider>
                                                                    <Routes/>
                                                                </ActionPanelContextProvider>
                                                            </ShortSaleContextProvider>
                                                        <GridSpinner/>
                                                        <ExportGridToCsv/>
                                                    </Container>
                                                    <AppFooter/>
                                                </HomepageContextProvider>
                                            </TradeBreakContextProvider>
                                        </TradeCaptureContextProvider>
                                    </EntityAccountsContextProvider>
                                </ReportsPanelContextProvider>
                            </QueriesPanelContextProvider>
                        </ToastContextProvider>
                    </UtilsContextProvider>
                </UserPreferencesContextProvider>
                {sessionExpired && <SessionExpiredModal/>}
            </>
        );
}

export default AppLayout;
