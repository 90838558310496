export const DEFAULT_GRID_PAGINATION_SIZE = 25;
export const ENABLE_SCROLL_BUTTONS_PAGINATION_SIZE = 20;
export const SELECTED_PAGINATION_SIZE = 'paginationSize';
export const VALIDATION_IN_JS = true
export const REGEX_DECIMAL_MATCH = "^[0-9]\\d*(\\.\\d+)?$";
export const REGEX_TRADE_ENTRY_WHOLE_NUMBER = "[0-9]+";
export const REGEX_TRADE_ENTRY_WHOLE_NUMBER_COMMA_OPTIONAL = "[0-9]+|^\\d{1,3}(,\\d{3})*$";
export const REGEX_TRADE_ENTRY_CLIENT_ID = "[A-Za-z0-9_.-]{0,50}";
export const REGEX_TRADE_ENTRY_IND_TRADE_CANCEL = "C?";
export const REGEX_TRADE_ENTRY_SECURITY_CODE = "[A-Za-z0-9\\s]{1,21}";
export const REGEX_TRADE_ENTRY_ACCOUNT_CODE = "[0-9]{8}";
export const REGEX_TRADE_ENTRY_ACCOUNT_TYPE = "(1|2|3)";
export const REGEX_TRADE_ENTRY_COMMISSION_TYPE = "(1|3|6)";
export const REGEX_TRADE_ENTRY_DECIMAL_NUMBER = "([0-9]+\\.?[0-9]*)$";
export const REGEX_TRADE_ENTRY_BROKER_CODE = "[a-zA-Z]{4,20}";
export const REGEX_TRADE_ENTRY_TRADE_DATE = "^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$";
export const REGEX_TRADE_ENTRY_SETTLE_DATE = "^$|^(1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4}$";
export const REGEX_TRADE_ENTRY_CURRENCY =  "[A-Z]{1,3}";
export const REGEX_TRADE_ENTRY_COMMENTS =  "[A-Za-z0-9-\\.\\, _;\\(\\)\\+:\\$\\%\\*\\/]{0,249}";

// this is include multiple securities
export const REGEX_SHORT_SALE_UPLOAD_VALIDATION_SECURITY = "[a-zA-Z0-9 \\,]+";
// only for one security on short sale locate page
export const REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY = "[A-Za-z0-9\\s]{1,21}";
export const REGEX_TRADE_SEARCH_CRITERIA_STATUS = "ALL|PEND|SEND|CANCEL|REVOKE|REJECT|ACCEPT|TIMEOUT|MANUAL|INTRANSIT";



export const LIST_TRADE_ENTRY_TRADE_TYPE = ["BC","B", "S", "SS"]
export const REGEX_WIRES_NEW_COMMENTS =  "[\\s\\S]{1,2000}";
export const REGEX_WIRES_NEW_REASON =  "[\\s\\S]{1,120}";
export const REGEX_WIRES_NEW_TEMPLATE_NAME =  "[\\s\\S]{1,30}";
export const REGEX_WIRES_NEW_WIRE_INST =  "[\\s\\S]{1,512}";
export const REGEX_WIRES_NEW_INCOMING_CURRENCY_CODE =  "[A-Z]{1,3}";
export const REGEX_WIRES_NEW_OUTGOING_CURRENCY_CODE =  "[A-Z]{1,3}";
export const REGEX_WIRES_NEW_CLIENT_CODE =  "[0-9]{1,8}";
export const SELECTED_ACCOUNT = "selectedAccount";
export const SELECTED_ENTITY = "selectedEntity";
export const ACCOUNTS_OPTIONS = "accountsOptions";
export const TRADE_BREAK_STATE = "tradeBreakState";
//
export const GRID_COLUMN_PROPERTY = {filter: 'agMultiColumnFilter', floatingFilter: true}
export const NO_SPECIAL_CHARACTERS_WITHSPACE = /^[a-zA-Z0-9 ]*$/;
export const MAX_LIMIT_TRADE_AMOUNT = 10000000;
export const MAX_LIMIT_TRADE_QUANTITY= 10000000000000000;
