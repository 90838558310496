import React, {useContext, useEffect,useState} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import * as URL from "service/UrlConstant";
import {WiresPanelContext} from "./context";
import {SecurityContext} from "context/security";
import {dateToString, getCurBusDate,
    getMinPermissibleBusDate, pickerDateFormatToDate, yearMonthDayToPickerDateFormat} from "utils/FormatUtils";
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import AuthenticationService from "service/AuthenticationService";
import {EntityAccountsContext} from "components/entity-accounts/context";

import AddNewWire from "./AddNewWire";
import {WIRE_PANEL_VIEW_WIRE_PAGE} from "./context/WiresPanelContext";
import {ToastContext} from "context/Toast";
import {FilterContext} from "layouts/SidePanelLayout";
import {getAllErrorValuesAsString, filterFormErrors} from "../../utils/ErrorUtils";
import {VALIDATION_IN_JS} from "../../utils/Constants";
import UserRoleService, {WIRES_MENU_SUBMITTER_PERMISSION_KEY} from "../../service/UserRoleService";


export default function WiresFilter() {

    const {
        wirePanelPageState,
        wirePanelFilterState,setWirePanelFilterState,
        wireResultsDisplayState,setWireResultsDisplayState,
        clearWirePanelDisplayState,
        displayWirePanelPage,
        setShowGridToolbar,
        wireNewState,
        setWireNewState,
        clearWireNewState,
        hasWiresSubmitterPermission
    } = useContext(WiresPanelContext);
    const {entityAccounts, setShowAccounts, clearEntityAccounts, validateEntitySearch,setManagerAndAccountDetails, paramBadgeInfo, populateDefaultEntity, entityAccountsOptions} = useContext(EntityAccountsContext);
    const [showAddWire, setShowAddWire] = useState(false);
    const {makePostRequest} = useContext(SecurityContext);
    const {error} = useContext(ToastContext);
    const [formErrors, setFormErrors] = useState({search: {}});
    const {showFilter, setShowFilter} = useContext(FilterContext);
    let userName = AuthenticationService.getAuthenticatedUserName();


    const handleViewWires = (e) => {
        e.preventDefault();

        if (!wirePanelFilterState.status){
            wirePanelFilterState.status = "ALL"
        }
        let errors = {}
        if (VALIDATION_IN_JS){
            errors = validateSearch()
        }
        const newFormErrors = {...formErrors}
        newFormErrors['search'] = errors;
        setFormErrors(newFormErrors)
        if(Object.keys(errors).length!==0){
            return
        }

        const accountCode = (!!entityAccounts.accountCode?entityAccounts.accountCode:"ALL");

        const params = {
            legalEntity: entityAccounts.managerName,
            cdAcct:accountCode,
            clientCode: accountCode,
            startDate:dateToString(wirePanelFilterState.startDate),
            endDate:dateToString(wirePanelFilterState.endDate),
            status:wirePanelFilterState.status,
            templateIndicator:"N",
            deskViewIndicator:"N",
            exportIndicator:0
        }

        setShowFilter(!showFilter)

        const onSuccess = (res) => {
            const wireResultsDisplay = JSON.parse(JSON.stringify(wireResultsDisplayState));
            wireResultsDisplay.resData = ([...res.data]);
            wireResultsDisplay.isSavedWire = false;
            wireResultsDisplay.savedName = "";
            wireResultsDisplay.savedWireListId = "";
            wireResultsDisplay.wireSearch = {
                userCode:'',
                managerId: entityAccounts.managerId,
                managerName : entityAccounts.managerName,
                accountId: entityAccounts.accountId,
                accountName : entityAccounts.accountName,
                accountCode : accountCode,
                subManagerId : entityAccounts.subManagerId,
                subManagerName : entityAccounts.subManagerNameShort,
                legalEntity:'',
                clientCode: accountCode,
                status: wirePanelFilterState.status,
                startDate:wirePanelFilterState.startDate,
                endDate:wirePanelFilterState.endDate,
                badgeInfo: paramBadgeInfo()
            };
            setShowGridToolbar(res.data && res.data.length > 0)
            setWireResultsDisplayState(wireResultsDisplay);
            displayWirePanelPage(WIRE_PANEL_VIEW_WIRE_PAGE);

        }
        const onError = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error('Error when retrieving wires - ', errorMsg);
            error("There was an error when loading wires");
        }
        makePostRequest(params, URL.WIRES_WIRE_SEARCH, onSuccess, onError);

    }

    const validateSearch = ()=> {
        if(!wirePanelFilterState.status){
            wirePanelFilterState.status = "ALL";
            setWirePanelFilterState({...wirePanelFilterState, ['status']:"ALL"});
        }

        let errors = {}
        errors = validateEntitySearch();

        if (!wirePanelFilterState.startDate)
            errors['startDate'] = 'Please enter start date'

        if (!wirePanelFilterState.endDate)
            errors['endDate'] = 'Please enter end date'

        if (wirePanelFilterState.startDate && wirePanelFilterState.endDate){
            let startDate = new Date(wirePanelFilterState.startDate);
            let endDate = new Date(wirePanelFilterState.endDate);
            if (startDate.getTime() > endDate.getTime()){
                errors['startDate'] = 'Please ensure that the start date is before the end date'
                errors['endDate'] = 'Please ensure that the start date is before the end date'
            }
        }

        if (wirePanelFilterState.startDate && (dateToString(wirePanelFilterState.startDate) < getMinPermissibleBusDate()))
            errors['startDate'] = `Please ensure that the start date is on or after ${pickerDateFormatToDate(getMinPermissibleBusDate())}`;

        if (wirePanelFilterState.startDate &&  (dateToString(wirePanelFilterState.startDate) > getCurBusDate()))
            errors['startDate'] = `Please ensure that the start date is on or before ${pickerDateFormatToDate(getCurBusDate())}`;
        if (wirePanelFilterState.endDate &&  (dateToString(wirePanelFilterState.endDate) > getCurBusDate()))
            errors['endDate'] = `Please ensure that the end date is on or before ${pickerDateFormatToDate(getCurBusDate())}`;


        if (!wirePanelFilterState.status)
            errors['status'] = 'Please enter status'

        return errors
    }

    const handleClear = (e) => {
        clearWirePanelDisplayState();
        clearEntityAccounts();
    }

    const handleChange = (e, name) => {
        setWirePanelFilterState({...wirePanelFilterState, [e.currentTarget.name]:e.currentTarget.value});// [name]:e.currentTarget.options[e.currentTarget.selectedIndex].innerHTML});
        const newFormErrors = {...formErrors,...{'search': {[e.currentTarget.name]: ''}}}
        setFormErrors(newFormErrors);
    }

    const handleDateChange = (e) => {
        const targetName = e.currentTarget.name;
        const targetValue = e.currentTarget.value;
        setWirePanelFilterState({...wirePanelFilterState, [e.currentTarget.name]:e.currentTarget.value});
        filterFormErrors(targetName, targetValue,
            formErrors, setFormErrors, wirePanelFilterState)
    }

    const setWireNewStateOnClick = () => {
        setWireNewState(
            {
                ...wireNewState,
                ['managerId']: entityAccounts.managerId,
                ['managerName']: entityAccounts.managerName,
                ['clientCode']: entityAccounts.accountCode,
                ['accountId']: entityAccounts.accountId,
                ['accountName']: entityAccounts.accountName
            });
    }

    useEffect(() => {
        populateDefaultEntity(true)
    }, [entityAccountsOptions])

    useEffect(() => {
        setShowAccounts(true)
    },[])

    useEffect( () =>{
        if (WIRE_PANEL_VIEW_WIRE_PAGE === wirePanelPageState.currentPage && wireResultsDisplayState.isSavedWire) {
            let wirePanelFilterStateSaved = JSON.parse(JSON.stringify(wirePanelFilterState));
            wirePanelFilterStateSaved.managerId = wireResultsDisplayState.wireSearch.managerId;
            wirePanelFilterStateSaved.accountId = wireResultsDisplayState.wireSearch.accountId;
            wirePanelFilterStateSaved.clientCode = wireResultsDisplayState.wireSearch.clientCode;
            wirePanelFilterStateSaved.legalEntity = wireResultsDisplayState.wireSearch.legalEntity;
            wirePanelFilterStateSaved.status = wireResultsDisplayState.wireSearch.status;
            wirePanelFilterStateSaved.startDate = yearMonthDayToPickerDateFormat(wireResultsDisplayState.wireSearch.startDate);
            wirePanelFilterStateSaved.endDate = yearMonthDayToPickerDateFormat(wireResultsDisplayState.wireSearch.endDate);
            setWirePanelFilterState(wirePanelFilterStateSaved);

            setManagerAndAccountDetails(wireResultsDisplayState.wireSearch.managerId, wireResultsDisplayState.wireSearch.managerName,
                wireResultsDisplayState.wireSearch.accountId, wireResultsDisplayState.wireSearch.accountName,
                wireResultsDisplayState.wireSearch.accountCode, true);

        }
    },[wirePanelPageState])


    return(<>

        <Container fluid className="mt-md-3 mt-sm-0">
            <Form>
                <h2 className="d-md-block d-none">Search</h2>
                <Row>
                    <EntityAccountsComponent/>

                    <Col sm={12} md={12}>
                        <Form.Group controlId="formWireStatus" className={'td-select'}>
                            <Form.Label className="filter-input-label">Status</Form.Label>
                            <Form.Control as="select" name ='status' value={wirePanelFilterState.status} onChange={event => handleChange(event, 'status')} isInvalid={!!formErrors.search.status}>
                                <option value={"ALL"}>ALL</option>
                                <option value={"Pend"}>Pend</option>
                                <option value={"Appr"}>Approved</option>
                                <option value={"Cmplt"}>Completed</option>
                                <option value={"Reject"}>Reject</option>
                            </Form.Control>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.status}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={12}>
                        <Form.Group controlId="formWireStartDate">
                            <Form.Label className="filter-input-label">Start Date *</Form.Label>
                            <Form.Control type="date" name={'startDate'} value={wirePanelFilterState.startDate} onChange={handleDateChange} isInvalid={!!formErrors.search.startDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.startDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col sm={6} md={12}>
                        <Form.Group controlId="formWireEndDate">
                            <Form.Label className="filter-input-label">End Date *</Form.Label>
                            <Form.Control type="date" name={'endDate'} value={wirePanelFilterState.endDate} onChange={handleDateChange} isInvalid={!!formErrors.search.endDate}/>
                            <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.search.endDate}</b></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3" onClick={handleViewWires}>View Wires</button>
                    </Col>
                    <Col md={12}>
                        <button className="btn btn-block td-btn-secondary-clear my-3" onClick={handleClear}>Clear</button>
                    </Col>
                    <Col sm={12} md={12} xs={12}>
                        {hasWiresSubmitterPermission && <a className="btn btn-block td-btn-primary-light my-3" href="#"  onClick={() => {setWireNewStateOnClick(); setShowAddWire(true)}}>Request Wires</a>}
                    </Col>
                </Row>
            </Form>
            <AddNewWire show={showAddWire} onHide={()=> {clearWireNewState();  setShowAddWire(false);}}></AddNewWire>
        </Container>

    </>);

}