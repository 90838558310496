import {Col, Modal, Overlay} from "react-bootstrap";
import React from "react";
import useWindowDimensions from "../hooks/Dimensions";

export default function LabelOverLay(props) {
    const {target, show, message } = props;

    return (<Overlay target={target.current} show={show} placement="right" >
        <div style={{background:'#FDF2DF',marginLeft:'0.2em',padding:'0.2em',display:"flex",alignSelf: 'center', alignContent: 'center', alignItems: 'center'}}>
            <i style={{color:'#FF9500'}} className="td-icon icon-small td-icon-warning"></i><b>{message}</b>
        </div>
    </Overlay>);
}

LabelOverLay.defaultProps = {
    target: null, show: false, message: ''
}