import React, {useContext, useState,useEffect} from "react";
import {Col, Collapse, Container, Form, FormControl, InputGroup, Row, Toast} from "react-bootstrap";
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import SecurityQuantityExpand from "./SecurityQuantityExpand";
import {SecurityContext} from "context/security";
import {ShortSaleContext} from "./context";
import {SHORT_SALE_URL_LOCATE, SHORT_SALE_URL_SEARCH_APPROVALS, SECURITY_SEARCH_URL} from "service/UrlConstant";
import {dateToString, getCurrentDate} from "utils/FormatUtils";
import {ToastContext} from "context/Toast"
import {EntityAccountsComponent} from "components/entity-accounts/EntityAccountsComponent";
import {EntityAccountsContext} from "components/entity-accounts/context";
import {useHistory} from "react-router-dom";
import {SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD, SHORTSALE_APPROVAL_UPLOAD} from "routes/Routes";
import {FilterContext} from "layouts/SidePanelLayout";
import {
    REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY,
    REGEX_SHORT_SALE_UPLOAD_VALIDATION_SECURITY, VALIDATION_IN_JS
} from "../../utils/Constants";
import {validateValueOnRegex} from "../../utils/RegexUtils";
import {REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY_MSG} from "../../utils/ConstantsMsg";
import {getAllErrorValuesAsString} from "../../utils/ErrorUtils";
import {formatAmount} from "../../utils/NumberUtils";


export default function ShortSaleFilter() {

    let history = useHistory();
    const {setShortSaleApprovalSearchDisplayState,shortSaleState,setShortSaleState,clearShortSaleState, showSearch, setShowSearch,setShortSaleApprovalPasteFromClipboardState,shortSaleApprovalPasteFromClipboardState, setShowBadge} = useContext(ShortSaleContext);
    const {handlePageRequest,setPanelDisplayState,getErrorInitState, setSSAToolBarRefreshCriteria} = useContext(ShortSaleContext);
    const {entityAccounts,setShowAccounts,clearEntityAccounts,validateEntityAccountSearch,setShowAccountValidateAccount, paramBadgeInfo, populateDefaultEntity,entityAccountsOptions} = useContext(EntityAccountsContext);
    const [secQtyIndex, setSecQtyIndex] = useState(0);

    const {resetFlagLeft} = useContext(SecurityContext);
    const {makePostRequest, makeGetRequest} = useContext(SecurityContext);
    const {success,error, alert} = useContext(ToastContext);
    const [formErrors, setFormErrors] = useState(getErrorInitState());
    const {showFilter, setShowFilter} = useContext(FilterContext);

    useEffect(() => {
        setShowAccounts(showSearch);
        populateDefaultEntity(showSearch)
    }, [entityAccountsOptions])

    useEffect(()=>{
        setShowAccountValidateAccount(showSearch, false);
    },[showSearch]);

    useEffect(() => {
        if (resetFlagLeft.current) {
            // handleLocateClear();
            resetFlagLeft.current = false;
        }
    }, [resetFlagLeft.current])

    function divider() {
        return (<>
            <Col md={12} className="py-3">
                <hr/>
            </Col>
        </>)
    }
    
    const handleAddClick = () => {

        const shortSaleNewState = {...shortSaleState};
        const {secQty} = shortSaleNewState['securityLending'];
        // security, remove space from string
        if (shortSaleState.securityLending.security){
            shortSaleState.securityLending.security = shortSaleState.securityLending.security.replaceAll(/\s/g,"")
        }
        //

        let errors = {}
        if (VALIDATION_IN_JS){
           errors = validateLocate('handleAddClick');
        }

        if(Object.keys(errors).length!=0){
            const newFormErrors = {...formErrors,...{'securityLending':errors}}
            setFormErrors(newFormErrors)
            return
        }

        const param = {
            securityCode: shortSaleNewState.securityLending.security
        }

        const onSuccessResponse = (response) => {
            const securities = response.data
            let secMap = new Map()
            securities.forEach(sec => {
                secMap.set(sec["secIdentifier"],sec["longName"])
            })

            let index = secQtyIndex;
            const secQtyArr = [];
            let secArray = shortSaleNewState.securityLending.security.split(",")
            secArray.forEach(sec => {
                secQtyArr.push({
                    'index': index ,
                    'security': sec.toUpperCase(),
                    'secDescription': secMap.get(sec.toUpperCase()),
                    'quantity': parseInt(shortSaleNewState.securityLending.quantity,10)
                })
                index = index + 1;
            })

            setSecQtyIndex(index)
            shortSaleNewState['securityLending']['secQty'] = [...secQty, ...secQtyArr]
            shortSaleNewState['securityLending']['security'] = '';
            shortSaleNewState['securityLending']['quantity'] = '';
            setShortSaleState(shortSaleNewState);
        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error occurred while getting security description",errorMsg)
            error("Error occurred while getting security description. " + errorMsg);
        }

        makeGetRequest(SECURITY_SEARCH_URL, param
             , onSuccessResponse, onErrorResponse);

    }

    const onDeleteClick = (index) => {
        const shortSaleNewState = {...shortSaleState};
        const {secQty} = shortSaleNewState['securityLending'];
        const newSecQty = secQty.filter(e => e.index != index)
        shortSaleNewState['securityLending']['secQty'] = newSecQty
        setShortSaleState(shortSaleNewState);
    }

    const handleSearchClear = (e) => {
        clearShortSaleState();
        setFormErrors(getErrorInitState());
        handlePageRequest('defaultlanding');
    }

    const handleLocateClear = (e) => {
        clearShortSaleState();
        clearEntityAccounts(showSearch,true);
        setFormErrors(getErrorInitState());
        handlePageRequest('defaultlanding');
    }

    const validateSearch = ()=>{
        let errors = {}

        errors = validateEntityAccountSearch(false);

        if (!shortSaleState.search.startDate)
            errors['startDate'] = 'Please enter start date'

        if (!shortSaleState.search.endDate)
            errors['endDate'] = 'Please enter end date'

        return errors
    }

    const handleSecurityLendingOnChange = (e) => {
        const shortSaleNewState = {...shortSaleState}
        shortSaleNewState['securityLending'][e.currentTarget.name] = e.currentTarget.value;
        setShortSaleState(shortSaleNewState);
        const newFormErrors = {...formErrors,...{'securityLending': {[e.currentTarget.name]: ''}}}
        setFormErrors(newFormErrors);
    }


    const handleUploadDownloadClick = (e) => {
        history.push(SHORTSALE_APPROVAL_UPLOAD);
    }

    const handlePasteFromExcel = async  () => {
        if(!window.isSecureContext){
            alert("Sorry, this option is not supported by the browser")
            return
        }
        if(!entityAccounts.managerId ){
            error("Please select an entity")
            return
        }
        const text = await navigator.clipboard.readText();
        if(text.trim()==='') {
            error("Please copy before using this option")
            return
        }
        let secQtyLst=text.split('\r\n');
        let newlist={}
        let securityLst = [];
        secQtyLst.map((sec)=>{
            let secQty=sec.split("\t")
            if(secQty[0]!='Security' && secQty[0].trim()!=''){
                secQty[1] = formatAmount(secQty[1])
                securityLst.push(secQty[0])
                if(secQty[0] in newlist)
                    newlist[secQty[0]] = Number(newlist[secQty[0]]) + Number(secQty[1]);
                else
                    newlist[secQty[0]] = Number(secQty[1]);
            }
        })
        securityLst = [...new Set(securityLst)]
        const param = {
            securityCode: securityLst.toString()
        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error occurred while getting security description",errorMsg)
            error("Error occurred while getting security description. " + errorMsg);
        }
        const onSuccessResponse = (response) => {
            const securities = response.data
            let secMap = new Map()
            securities.forEach(sec => {
                secMap.set(sec["secIdentifier"],sec["longName"])
            })

            let index = secQtyIndex;
            const secQtyArr = [];
            let secArray = securityLst
            secArray.forEach(sec => {
                secQtyArr.push({
                    'index': index ,
                    'security': sec.toUpperCase(),
                    'secDescription': secMap.get(sec.toUpperCase()),
                    'quantity': newlist[sec],
                    'errmsgQuantity':isNaN(newlist[sec])?'The entered quantity is not valid':null,

                })
                index = index + 1;
            })
            setSecQtyIndex(index)
            setShortSaleApprovalPasteFromClipboardState({'security': securityLst.toString(),'secQtyArr':[...secQtyArr]})
            history.push(SHORTSALE_APPROVAL_PASTE_FROM_CLIPBOARD);
        }

            makeGetRequest(SECURITY_SEARCH_URL, param
                , onSuccessResponse, onErrorResponse);


    }

    const handleCollapseComponent = () => {
        handlePageRequest('defaultlanding');
        setShowSearch(!showSearch)
        setFormErrors(getErrorInitState());
    }

    const validateLocate = (target)=>{
        let errors = {}
        errors = validateEntityAccountSearch(false);
        if (shortSaleState.securityLending.security){
            let match = validateValueOnRegex(shortSaleState.securityLending.security,REGEX_SHORT_SALE_UPLOAD_VALIDATION_SECURITY )
            if (!match){
                errors['security'] = 'Please enter comma separated securities'
            } else {
                // check each security on regex
                let multipleSecurities = []
                if (shortSaleState.securityLending.security.includes(",")){
                    multipleSecurities = shortSaleState.securityLending.security.split(",")
                } else {
                    multipleSecurities.push(shortSaleState.securityLending.security)
                }
                for (let i =0; i<multipleSecurities.length; i++){
                    let oneSecurity = multipleSecurities[i]
                    let matchEach = validateValueOnRegex(oneSecurity, REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY)
                    if (!matchEach){
                        errors['security'] = REGEX_SHORT_SALE_UPLOAD_VALIDATION_EACH_SECURITY_MSG
                        break
                    }
                }
            }
        } else {
            if (target !== 'handleLocateClick'){
                errors['security'] = 'Please enter security'
            }
        }

        if (target == 'handleLocateClick') {
            if (shortSaleState.securityLending.secQty.length <= 0 && !shortSaleState.securityLending.security)
                errors['security'] = 'Please enter security'

            if (shortSaleState.securityLending.secQty.length <= 0 && (!shortSaleState.securityLending.quantity || shortSaleState.securityLending.quantity <= 0))
                errors['quantity'] = 'Please enter quantity'
        }else{
            if (!shortSaleState.securityLending.security)
                errors['security'] = 'Please enter security'

            if (!shortSaleState.securityLending.quantity || shortSaleState.securityLending.quantity <= 0)
                errors['quantity'] = 'Please enter quantity'
        }
        return errors
    }

    const handleLocateClick = (e) => {
        e.preventDefault();
        let errors = {}
        if (VALIDATION_IN_JS){
            errors = validateLocate('handleLocateClick')
        }
        const data = [];
        if(Object.keys(errors).length!=0 || shortSaleState.securityLending.secQty.length <=0){
            const shortSaleNewState = {...shortSaleState};
            // security, remove space from string
            if (shortSaleState.securityLending.security){
                shortSaleState.securityLending.security = shortSaleState.securityLending.security.replaceAll(/\s/g,"")
            }

            let locateObj = {
                "processingDate": getCurrentDate(),
                "managerId": entityAccounts.managerId,
                "counterPartyAcct" : entityAccounts.accountCode,
                "srcSecCd": shortSaleNewState.securityLending.security.toUpperCase(),
                "requestedQty": parseInt(shortSaleNewState.securityLending.quantity,10),
            };
            data.push(locateObj);
        }
        else{
            shortSaleState.securityLending.secQty.map(e => {
                let locateObj = {
                    "processingDate": getCurrentDate(),
                    "managerId": entityAccounts.managerId,
                    "counterPartyAcct" : entityAccounts.accountCode,
                    "srcSecCd": e.security,
                    "requestedQty": e.quantity,
                };
                data.push(locateObj);
            })
        }

        const searchCriteria =  {
            "managerId": entityAccounts.managerId,
            "subManagerId": '',
            "security": '',
            "fromProcessingDate": '',
            "toProcessingDate": ''
        }

        setShowFilter(!showFilter)

        const onSuccessResponse = (response) => {
            success("Locate Processed successfully");
            setShowBadge(true)
            //Set Refresh Request Criteria
            setSSAToolBarRefreshCriteria(response, entityAccounts);

            shortSaleState['currentPage'] = 'search';
            setPanelDisplayState({...shortSaleState});
            setShowSearch(showSearch)
            const shortSaleStateNew = {...shortSaleState}
            shortSaleStateNew.securityLending.secQty = []
            setShortSaleState(shortSaleStateNew)
            handleSearchClear(e)
        }

        const onErrorResponse = (err) => {
            let errorMsg = getAllErrorValuesAsString(err.response.data)
            console.error("Error in processing",errorMsg)
            error("Error in processing. " + errorMsg)
        }

        makePostRequest(data,
            SHORT_SALE_URL_LOCATE, onSuccessResponse, onErrorResponse);
    };

    const handleKeyPress = (e) => {
        if(e.keyCode === 13 && e.key === "Enter"){
            handleAddClick();
        }
    }

    return (<>
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form className={'mt-4'} onSubmit={(e) => e.preventDefault()}>
                <h2 className="d-none d-md-block">Upload</h2>
                <Row>
                    <Container fluid className="mt-md-3 mt-sm-0">
                        <Row>
                            <EntityAccountsComponent ssaEntityWidthFull={true}/>
                        </Row>
                    </Container>

                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3"
                                onClick={handlePasteFromExcel}>Paste from excel
                        </button>
                    </Col>
                    <Col md={12}>
                        <p className='text-center font-weight-bold'>Or</p>
                    </Col>

                    <Col md={12}>
                        <button className="btn btn-block td-btn-primary-light my-3"
                                onClick={handleUploadDownloadClick}>Upload / Download Template
                        </button>
                    </Col>
                </Row>
            </Form>
        </Container>
        {divider()}
        <Container fluid className="mt-md-3 mt-sm-0">
            <Form className={'mt-4'} onSubmit={(e) => e.preventDefault()}>
                <h2 className="d-none d-md-block">Securities Lending</h2>
                <Row>
                    <Container fluid className="mt-md-3 mt-sm-0">
                        <Row>
                            <EntityAccountsComponent ssaEntityWidthFull={true}/>
                            <Col xs={12} sm={12} md={12} className={'py-0'}>
                                <Form.Group controlId="formGridSecurity">
                                    <label className='my-0'>Security *</label>
                                    <InputGroup>
                                        <FormControl className='pl-3'
                                                     placeholder="Name or Symbol" name="security"
                                                     value={shortSaleState.securityLending.security}
                                                     onChange={handleSecurityLendingOnChange}
                                                     isInvalid={!!formErrors.securityLending.security}
                                        />
                                        <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.securityLending.security}</b></Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} className={'py-0'}>
                                <Form.Group controlId="formGridPassword">
                                    <Form.Label className="filter-input-label">Quantity *</Form.Label>
                                    <Form.Control type="number" min={1} placeholder='Quantity' name="quantity"
                                                  value={shortSaleState.securityLending.quantity}
                                                  onChange={handleSecurityLendingOnChange}
                                                  onKeyPress={handleKeyPress}
                                                  isInvalid={!!formErrors.securityLending.quantity}
                                    />
                                    <Form.Control.Feedback type={"invalid"}><b><i className="bi bi-exclamation-circle"></i>{formErrors.securityLending.quantity}</b></Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={6} sm={6} md={6} className={'py-0'}>
                                <button onClick={handleAddClick}
                                        className="btn btn-block td-btn-primary-light my-4 py-1"
                                        type='submit' style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    textAlign: 'center',
                                    alignItems: 'center'
                                }}><i className='td-icon icon-small td-icon-expand p-0 m-0 pr-3'
                                      style={{fontSize: '2em'}}/> Add Multiple
                                </button>

                            </Col>
                            {shortSaleState.securityLending.secQty.length != 0 &&
                                <Col xs={12} sm={12} md={12} className={'py-3'}>
                                    <SecurityQuantityExpand secQty={shortSaleState.securityLending.secQty}
                                                            onDeleteClick={onDeleteClick}/>
                                </Col>}

                            {divider()}
                            <Col md={12}>
                                <button className="btn btn-block td-btn-primary-light my-3" type='submit'
                                        onClick={handleLocateClick}>Locate
                                </button>
                            </Col>
                            <Col md={12}>
                                <button className="btn btn-block td-btn-secondary-clear my-3" onClick={handleLocateClear}>Clear</button>
                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Form>
        </Container>
    </>);

}
