import {Row, Col, Container, Form} from "react-bootstrap";
import refreshlogo from "../../assets/image/refresh.svg";
import React, {createRef, useContext, useState} from "react";
import useWindowDimensions from "hooks/Dimensions";
import {ActionPanelContext} from "context/action";

export default function ToolbarRefresh(props) {
    const {isMobile} = useWindowDimensions();
    const {handleRefresh} = useContext(ActionPanelContext);
    const pageStatus = createRef();

    const onRefreshClicked = () => {
        const onSuccess = () => {
        }
        const onError = () => {
        }
        handleRefresh(props.refreshCriteria, onSuccess, onError);
    }

    return (<>
        <Col style={{padding: 0, textAlign: "center", alignSelf: "center"}}
             className={' d-none d-sm-block'}>
            <button style={{border: 'none', background: "none",paddingLeft: 0,paddingRight:0}} onClick={onRefreshClicked}
            >
                <img
                    src={refreshlogo}
                    height="25"
                    className="d-inline-block"
                    alt="React Bootstrap logo"
                />
            </button>
        </Col>
    </>);
}
